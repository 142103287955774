import { render, staticRenderFns } from "./Privacy.vue?vue&type=template&id=4bb1f542&scoped=true&"
var script = {}
import style0 from "@/assets/scss/style.scss?vue&type=style&index=0&id=4bb1f542&prod&scoped=true&lang=scss&"
import style1 from "@/assets/scss/guide.sass?vue&type=style&index=1&id=4bb1f542&prod&scoped=true&lang=sass&"
import style2 from "@/assets/scss/other.sass?vue&type=style&index=2&id=4bb1f542&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bb1f542",
  null
  
)

export default component.exports